import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
      // Arange TC users
    fetchArangeEquityBondTCUsers(ctx, user) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getArangeEquityBondTCUsers`, user)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getLast7DaysJournals(ctx) {
        return new Promise((resolve, reject) => {
          axiosIns
            .get(`getLast7DaysJournals`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      searchJournalEntries(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`searchJournalEntries`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getLastSearchJournal(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getLastSearchJournal`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      searchJournalEntriesbyValueDate(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`searchJournalEntriesbyValueDate`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getSelectedAccountNames(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getSelectedAccountNames`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getselectedAccountCodes(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getselectedAccountCodes`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
  },
};

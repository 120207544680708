<template>
  <div class=" mt-2">
    <b-card class="mb-0" :title="$t('journals.titles.journals')">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <hr />
        <div class="m-1">
          <b-row>
            <b-col cols="12" xl="12" md="12" sm="12" class="d-flex">
              <b-col
                cols="8"
                xl="8"
                md="8"
                sm="12"
                class="d-flex"
                style="width: 100%;align-items: center !important;"
              >
                <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-info"
                    size="sm"
                    class="top-btns"
                    @click="searchForEntriesModal"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="text-nowrap">{{
                      $t("journals.buttons.search_for_entries")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-dark"
                    size="sm"
                    class="top-btns"
                    @click="runLastSearch"
                    :disabled="
                      typeof lastJournalSearchData != 'Object'
                        ? !lastJournalSearchData.find(
                            (data) => data.user == this.user.name
                          )
                        : true
                    "
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="text-nowrap">{{
                      $t("journals.buttons.run_last_search")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <b-input-group>
                    <flat-pickr
                      :placeholder="$t('journals.journal_form.entry_date')"
                      @input="searchRequestEntry"
                      v-model="entryRangeDate"
                      class="form-control"
                      :config="dateConfig"
                    />
                    <b-input-group-append>
                      <b-button
                        @click="clearEntriestDate()"
                        variant="success"
                        >{{ $t("general_buttons.clear") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <b-input-group>
                    <flat-pickr
                      :placeholder="$t('journals.journal_form.value_date')"
                      @input="searchRequestValue"
                      v-model="valueDateRange"
                      class="form-control"
                      :config="dateConfig"
                    />
                    <b-input-group-append>
                      <b-button
                        @click="clearValuesDate()"
                        variant="success"
                        >{{ $t("general_buttons.clear") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col>


              </b-col>
            </b-col>
          </b-row>
        </div>

        <b-table
          hover
          ref="refJournalsTable"
          class="position-relative p-2"
          :items="allJournalData"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          @row-clicked="onRowSelected"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('journals.messages.no_matching_journals_found')"
          :current-page="currentPage"
          :per-page="perPage"
          caption-top
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortByDesc"
          :tbody-tr-class="legendClass"
        >
          <!-- :tbody-tr-class="legendClass" -->

          <template #cell(ID)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.value }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TcUti -->
          <template #cell(TcUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUti ? data.item.TcUti : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountName -->
          <template #cell(AccountName)="data">
            
            <b v-if="!$Can('see_journal_accountName')">
              <span class="font-weight-bold">
                <strong>{{
                  data.item.AccountNumber == '0000000001' || data.item.AccountNumber == '0000000002'  ? 'System Account' : data.item.AccountName
                }}</strong>
              </span>
            </b>
            <b v-else
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountName ? data.item.AccountName : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountNumber -->
          <template #cell(AccountNumber)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountNumber ? data.item.AccountNumber : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountCode -->
          <template #cell(AccountCode)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountCode ? data.item.AccountCode : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ValueDate -->
          <template #cell(ValueDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ dateFormat(data.item.ValueDate) }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Underlying -->
          <template #cell(Underlying)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Underlying ? data.item.Underlying : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Amount -->
          <template #cell(Amount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Amount ? formatPrice(data.item.Amount, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Price -->
          <template #cell(Price)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Price ? formatPrice(data.item.Price, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TransactionType -->
          <template #cell(TransactionType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TransactionType ? data.item.TransactionType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: EntryDescription -->
          <template #cell(EntryDescription)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.EntryDescription ? data.item.EntryDescription : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: RelatedTcUti -->
          <template #cell(RelatedTcUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.RelatedTcUti ? data.item.RelatedTcUti : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: VerificationUser -->
          <template #cell(VerificationUser)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.VerificationUser ? data.item.VerificationUser : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Reversed -->
          <template #cell(Reversed)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Reversed == 0 ? false : true }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ReversedBy -->
          <template #cell(ReversedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ReversedBy ? data.item.ReversedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ReversedDateTime -->
          <template #cell(ReversedDateTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ReversedDateTime != "1900-01-01 00:00:00"
                    ? dateFormatWithTime(data.item.ReversedDateTime)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveDateTime -->
          <template #cell(SaveDateTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveDateTime != "1900-01-01 00:00:00"
                    ? dateFormatWithTime(data.item.SaveDateTime)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- journal controls section -->
          <template #row-details="row">
            <b-card :title="$t('journals.titles.journal_entry_control')">
              <b-col cols="12">
                <hr />
              </b-col>
              <div
                class="d-flex mb-1"
                style="max-width:1300px!important;overflow-x: auto;"
              >
                <b-col v-if="$Can('code_journal')" cols="3" md="3" sm="12" class="p-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-success"
                    size="sm"
                    class="top-btns"
                    @click="getJournalEntriesAccountCode(row.item)"
                  >
                    <feather-icon
                      icon="HashIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("journals.buttons.journal_entries_account_codes")
                    }}</span>
                  </b-button>
                </b-col>
                <div
                  class="vl"
                  style="border-left: 1px solid #ebe9f1;height: 100px;"
                ></div>
                <b-col v-if="$Can('name_journal')" cols="3" md="3" sm="12" class="p-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-warning"
                    size="sm"
                    class="top-btns"
                    @click="getJournalEntriesAccountName(row.item)"
                  >
                    <feather-icon
                      icon="UsersIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("journals.buttons.journal_entries_account_names")
                    }}</span>
                  </b-button>
                </b-col>
              </div>
              <b-col cols="12">
                <hr />
              </b-col>
              <!-- <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Hide Details
                  </b-button> -->
            </b-card>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    {{ /*journals search modal*/ }}
    <b-modal
      id="journalsModal"
      v-model="journalSearchModal"
      :title="$t('journals.titles.journal_search_form')"
      @hidden="resetJournalModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('journals.buttons.search')"
      :cancel-title="$t('journals.buttons.cancel')"
      @ok="searchForEntries"
    >
      <b-overlay :show="JournalModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="createValidation">
            <b-form>
              <b-row class="mt-2">
                <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <b-card
                    :title="$t('journals.titles.client_counterpart_details')"
                  >
                    <!-- UTI-->
                    <b-form-group
                      :label="$t('journals.journal_form.uti')"
                      label-for="Uti"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.uti')"
                      >
                        <b-form-input
                          id="Uti"
                          size="sm"
                          type="text"
                          v-model="journalData.Uti"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Name-->
                    <b-form-group
                      :label="$t('journals.journal_form.account_name')"
                      label-for="securityType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.account_name')"
                      >
                        <v-select
                          v-model="journalData.AccountName"
                          id="securityType"
                          :state="errors.length > 0 ? false : null"
                          class="select-size-sm"
                          :clearable="false"
                          @input="(journalData.AccountNumber = optAccountNumbers.find(e => e.AccountName === journalData.AccountName).AccountNumber); 
                          (optAccountCodes = optAccountTempCodes.filter(e => e.AccountName === journalData.AccountName));"
                          :options="optAccountNames"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account number-->
                    <b-form-group
                      :label="$t('journals.journal_form.account_number')"
                      label-for="account_number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.account_number')"
                      >
                        <b-form-input
                          id="account_number"
                          size="sm"
                          type="text"
                          v-model="journalData.AccountNumber"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Code-->
                    <b-form-group
                      :label="$t('journals.journal_form.account_code')"
                      label-for="account_code"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.account_code')"
                      >
                        <v-select
                          v-model="journalData.AccountCode"
                          id="account_code"
                          :state="errors.length > 0 ? false : null"
                          class="select-size-sm"
                          label="AccountCode"
                          :reduce="(val) => val.AccountCode"
                          :clearable="false"
                          :options="optAccountCodes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Value date-->
                    <b-form-group
                      :label="$t('journals.journal_form.value_date')"
                      label-for="value_date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.value_date')"
                      >
                        <flat-pickr
                          :placeholder="$t('journals.journal_form.value_date')"
                          v-model="valueDateRange"
                          onClose="testClose"
                          class="form-control"
                          :config="dateConfig"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Currency-->
                    <b-form-group
                      :label="$t('journals.journal_form.currency')"
                      label-for="currency"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.currency')"
                      >
                        <v-select
                          v-model="journalData.Currency"
                          id="currency"
                          :state="errors.length > 0 ? false : null"
                          class="select-size-sm"
                          :clearable="false"
                          :options="optCurrencies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Amount-->
                    <b-form-group
                      :label="$t('journals.journal_form.amount')"
                      label-for="amount"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.amount')"
                      >
                        <b-form-input
                          id="amount"
                          size="sm"
                          type="text"
                          v-model="journalData.Amount"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Cost-->
                    <b-form-group
                      :label="$t('journals.journal_form.cost')"
                      label-for="cost"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.cost')"
                      >
                        <b-form-input
                          id="cost"
                          size="sm"
                          type="text"
                          v-model="journalData.Cost"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Transaction Type-->
                    <b-form-group
                      :label="$t('journals.journal_form.transaction_type')"
                      label-for="transaction_type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.transaction_type')"
                      >
                        <v-select
                          v-model="journalData.TransactionType"
                          id="transaction_type"
                          :state="errors.length > 0 ? false : null"
                          class="select-size-sm"
                          :clearable="false"
                          :options="optTransactionTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('journals.journal_form.product_type')"
                      label-for="transaction_type"
                    >
                        <v-select
                          v-model="journalData.ProductType"
                          id="product_type"
                          class="select-size-sm"
                          :options="optProductTypes"
                        />
              
                    </b-form-group>

                    <b-form-group
                      :label="$t('journals.journal_form.cost_center')"
                      label-for="cost_center"
                    >
             
                        <v-select
                          v-model="journalData.CostCenter"
                          id="transaction_type"
                          class="select-size-sm"
                          :clearable="false"
                          :options="optCostCenters"
                        />
              
                    </b-form-group>


                  </b-card>
                </b-col>

                <b-col cols="12" xl="1" lg="1" md="1" sm="1"></b-col>

                <b-col cols="12" xl="5" lg="5" md="5" sm="5">
                  <b-card>
                    <!-- Description-->
               

                    <b-form-group
                      :label="$t('journals.journal_form.description')"
                      label-for="description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.description')"
                      >
                        <b-form-input
                          id="description"
                          size="sm"
                          type="text"
                          v-model="journalData.Description"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>



                    <!--Related Trade Uti -->
                    <b-form-group
                      :label="$t('journals.journal_form.related_trade_uti')"
                      label-for="related_trade_uti"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.related_trade_uti')"
                      >
                        <b-form-input
                          id="related_trade_uti"
                          size="sm"
                          type="text"
                          v-model="journalData.RelatedTradeUti"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Trade Ops users-->
                    <b-form-group
                      :label="$t('journals.journal_form.trade_ops_user')"
                      label-for="trade_ops_user"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.trade_ops_user')"
                      >
                        <v-select
                          v-model="journalData.TradeOpsUser"
                          id="trade_ops_user"
                          :state="errors.length > 0 ? false : null"
                          class="select-size-sm"
                         
                          :clearable="false"
                          :options="optTradeOpsUSers"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Entry From-->
                    <b-form-group
                      :label="$t('journals.journal_form.entry_date')"
                      label-for="MaturityDate"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('journals.journal_form.entry_date')"
                      >
                        <flat-pickr
                        :placeholder="$t('journals.journal_form.entry_date')"
                          v-model="entryRangeDate"
                          onClose="testClose"
                          class="form-control"
                          :config="dateConfig"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Select Options Radio -->
                    <b-form-group>
                      <b-form-radio-group
                        v-model="journalData.selectopt"
                        :options="selectOptions"
                        value-field="item"
                        text-field="name"
                        class="demo-inline-spacing"
                      />
                    </b-form-group>

                    <!--Entry ID -->
                    <b-col cols="6" class="p-0">
                      <b-form-group
                        :label="$t('journals.journal_form.entry_id')"
                        label-for="entry_id"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('journals.journal_form.entry_id')"
                        >
                          <b-form-input
                            id="entry_id"
                            size="sm"
                            type="text"
                            v-model="journalData.ID"
                            :state="errors.length > 0 ? false : null"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="JournalModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("journals.messages.journal_searching_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** journal search modal*/ }}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import journalsStoreModule from "./journalsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "cleave.js/dist/addons/cleave-phone.us";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import { type } from "os";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    BTooltip,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const JOURNAL_APP_STORE_MODULE_NAME = "journal";
    // Register module
    if (!store.hasModule(JOURNAL_APP_STORE_MODULE_NAME))
      store.registerModule(JOURNAL_APP_STORE_MODULE_NAME, journalsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOURNAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(JOURNAL_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      allJournalData: [],
      journalSearchModal: false,
      JournalModalLoading: false,
      entryRangeDate: null,
      allJournalNotFilterData: [],
      valueDateRange:null,
      journalData: {
        ID: null,
        Uti: null,
        AccountName: null,
        AccountNumber: null,
        AccountCode: null,
        ValueDate: null,
        Currency: null,
        Amount: null,
        Cost: null,
        TransactionType: null,
        ProductType: null,
        CostCenter: null,
        Description: null,
        RelatedTradeUti: null,
        TradeOpsUser: null,
        EntryDate: null,
        EntryFrom: null,
        EntryTo: null,
        ValueFrom: null,
        ValueTo: null,
        selectopt: "All Entries",
      },
      optTradeOpsUSers: [],
      optAccountNames: [],
      optAccountNumbers: [],
      optAccountCodes: [],
      optAccountTempCodes: [],
      optCurrencies: [],
      optCostCenters: [],
      optProductTypes: [
      "Equity",
        "Bond",
        "FX",
        "Transfers",
      ],


      optTransactionTypes: [
        "Transfer In",
        "Transfer Out",
        "Internal Transfer",
        "Fee",
      ],
      selectOptions: [
        { item: "Only Not Reversed", name: "Only Not Reversed" },
        { item: "Only Reversed", name: "Only Reversed" },
        { item: "All Entries", name: "All Entries" },
      ],
      filterOn: ["AccountName", "EntryDescription"],
      sortByDesc: true,
      sortBy: "ID",
      tableColumns: [
        {
          key: "ID",
          label: "ID",
          class: "text-center w-0 padding_08",
        },
        {
          key: "TcUti",
          label: this.$t("journals.table.sfc_uti"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "AccountName",
          label: this.$t("journals.table.account_name"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "AccountNumber",
          label: this.$t("journals.table.account_number"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "AccountCode",
          label: this.$t("journals.table.account_code"),
          sortable: false,
          class: "text-center padding_08",
        },

        {
          key: "ValueDate",
          label: this.$t("journals.table.value_date"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "Underlying",
          label: this.$t("journals.table.underlying_ccy"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "Amount",
          label: this.$t("journals.table.amount"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "Price",
          label: this.$t("journals.table.cost"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "TransactionType",
          label: this.$t("journals.table.transaction_type"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "EntryDescription",
          label: this.$t("journals.table.description"),
          sortable: false,
          class: "text-center padding_08",
        },

        {
          key: "RelatedTcUti",
          label: this.$t("journals.table.related_sfc_uti"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "VerificationUser",
          label: this.$t("journals.table.operations_user"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "Reversed",
          label: this.$t("journals.table.reversed"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "ReversedBy",
          label: this.$t("journals.table.reversed_by"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "ReversedDateTime",
          label: this.$t("journals.table.reversed_date_time"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "SaveDateTime",
          label: this.$t("journals.table.save_date_time"),
          sortable: false,
          class: "text-center padding_08",
        },
      ],
      perPage: 15,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [15, 25, 50, 100],
      filter: null,

      loading: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        mode: "range",
      },
    };
  },
  computed: {
    ...mapState({
      lastJournalSearchData: (state) => state.runLastJournalSearchData,
    }),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser", "getLastSearchJournal"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getLast7DaysJournals()
  },
  methods: {
    ...mapActions(["saveLastSearchJournals"]),
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },
    getLast7DaysJournals() {
      this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("journal/getLast7DaysJournals")
            .then((res) => {
              this.totalRows = res.data.length;
              this.allJournalData = [];
              this.allJournalData = res.data;
              this.allJournalNotFilterData = res.data;
              this.JournalModalLoading = false;
              this.loading = false;
              this.journalSearchModal = false;
            })
            .catch((error) => {
              this.loading = false;
              this.databaseErrorMessage();
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    // search modal
    searchForEntriesModal() {
      this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("journal/fetchArangeEquityBondTCUsers", {
              auth: this.user,
              teamName: this.user.role,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.optTradeOpsUSers = res.data.users;
                this.optAccountNames = res.data.accounts;
                this.optAccountCodes = res.data.accountCodes;
                this.optAccountTempCodes = res.data.accountCodes;
                this.optAccountNumbers = res.data.accountNumbers;
                this.optCostCenters = res.data.costCenters;
                this.optCurrencies = res.data.currencies;
              } else if (res.data.info == "no_users") {
                this.warningMessage(this.$t("journals.messages.no_user_found"));
              } else {
                this.warningMessage(this.$t("journals.messages.no_user_found"));
              }
            })
            .catch((error) => {
              console.log(error.message);
              this.loading = false;
            });
          this.loading = false;
          this.journalSearchModal = true;
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    // search entries
    searchForEntries(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
          this.JournalModalLoading = true;
          this.journalData.ValueDate =
            this.journalData.ValueDate != null
              ? this.YMDdateFormat(this.journalData.ValueDate)
              : null;

              this.journalData.EntryDate =
            this.journalData.EntryDate != null
              ? this.YMDdateFormat(this.journalData.EntryDate)
              : null;

          this.journalData.EntryFrom =
            this.journalData.EntryFrom != null
              ? this.YMDdateFormat(this.journalData.EntryFrom)
              : null;

          this.journalData.EntryTo =
            this.journalData.EntryTo != null
              ? this.YMDdateFormat(this.journalData.EntryTo)
              : null;

          this.journalData.ValueFrom =
            this.journalData.ValueFrom != null
              ? this.YMDdateFormat(this.journalData.ValueFrom)
              : null;

          this.journalData.ValueTo =
            this.journalData.ValueTo != null
              ? this.YMDdateFormat(this.journalData.ValueTo)
              : null;

          store
            .dispatch("journal/searchJournalEntries", {
              auth: this.user,
              journalData: this.journalData,
            })
            .then((res) => {
              if (res.data.length > 0) {
                this.saveLastSearchJournals(this.journalData);
              }
              this.totalRows = res.data.length;
              this.allJournalData = [];
              this.allJournalData = res.data;
              this.allJournalNotFilterData = res.data;
              this.JournalModalLoading = false;
              this.loading = false;
              this.journalSearchModal = false;
              this.entryRangeDate = null

              this.valueDateRange = null
              this.journalData.ValueDate = null
              this.journalData.EntryDate = null
              this.journalData.ValueFrom = null
              this.journalData.ValueTo = null
              this.journalData.EntryFrom = null
              this.journalData.EntryTo = null
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
              this.JournalModalLoading = false;
              this.entryRangeDate = null
              this.valueDateRange = null
              this.journalData.ValueDate = null
              this.journalData.EntryDate = null
              this.journalData.ValueFrom = null
              this.journalData.ValueTo = null
              this.journalData.EntryFrom = null
              this.journalData.EntryTo = null
            });
         
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },


    searchRequestEntry(){
      if(this.journalData.EntryFrom != null && this.journalData.EntryTo != null ){
   
          this.journalData.EntryFrom =
            this.journalData.EntryFrom != null
              ? this.YMDdateFormat(this.journalData.EntryFrom)
              : null;

          this.journalData.EntryTo =
            this.journalData.EntryTo != null
              ? this.YMDdateFormat(this.journalData.EntryTo)
              : null;

          store
            .dispatch("journal/searchJournalEntries", {
              auth: this.user,
              journalData: this.journalData,
            })
            .then((res) => {
              if (res.data.length > 0) {
                this.saveLastSearchJournals(this.journalData);
              }
              this.totalRows = res.data.length;
              this.allJournalData = [];
              this.allJournalData = res.data;
              this.allJournalNotFilterData = res.data;
              this.JournalModalLoading = false;
              this.loading = false;
              this.journalSearchModal = false;


            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
              this.JournalModalLoading = false;
              this.entryRangeDate = null
              this.valueDateRange = null
              this.journalData.ValueDate = null
              this.journalData.EntryDate = null
              this.journalData.ValueFrom = null
              this.journalData.ValueTo = null
              this.journalData.EntryFrom = null
              this.journalData.EntryTo = null
            });
      }
      

    },

    
    searchRequestValue(){
      if(this.journalData.ValueFrom != null && this.journalData.ValueTo != null ){
   
          this.journalData.ValueFrom =
            this.journalData.ValueFrom != null
              ? this.YMDdateFormat(this.journalData.ValueFrom)
              : null;

          this.journalData.ValueTo =
            this.journalData.ValueTo != null
              ? this.YMDdateFormat(this.journalData.ValueTo)
              : null;

          store
            .dispatch("journal/searchJournalEntries", {
              auth: this.user,
              journalData: this.journalData,
            })
            .then((res) => {
              if (res.data.length > 0) {
                this.saveLastSearchJournals(this.journalData);
              }
              this.totalRows = res.data.length;
              this.allJournalData = [];
              this.allJournalData = res.data;
              this.allJournalNotFilterData = res.data;
              this.JournalModalLoading = false;
              this.loading = false;
              this.journalSearchModal = false;
  
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
              this.JournalModalLoading = false;
              this.entryRangeDate = null
              this.valueDateRange = null
              this.journalData.ValueDate = null
              this.journalData.EntryDate = null
              this.journalData.ValueFrom = null
              this.journalData.ValueTo = null
              this.journalData.EntryFrom = null
              this.journalData.EntryTo = null
            });
      }
      

    },
    runLastSearch() {
      this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          if (this.getLastSearchJournal) {
            store
              .dispatch("journal/getLastSearchJournal", {
                auth: this.user,
                journalData: this.getLastSearchJournal,
              })
              .then((res) => {
                this.allJournalData = [];
                this.totalRows = res.data.length;
                this.allJournalData = res.data;
                this.allJournalNotFilterData = res.data;
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;
                console.log(error);
              });
          } else {
            this.notFoundSearchData();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getFilterEntriesDate() {
      this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
    

            this.loading = true;
            store
              .dispatch("journal/searchJournalEntriesbyValueDate", {
                auth: this.user,
                journalData : this.journalData

              
              })
              .then((res) => {
                this.totalRows = res.data.length;
                this.allJournalData = [];
                this.allJournalData = res.data;
                this.loading = false;
              })
              .catch((error) => {
                this.databaseErrorMessage();
                console.log(error);
                this.loading = false;
              });
       
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },
    clearEntriestDate() {
      if (
        this.lastJournalSearchData.find((data) => data.user == this.user.name)
      ) {
        this.runLastSearch();

        this.entryRangeDate = null
        this.journalData.EntryDate = null
        this.journalData.EntryFrom = null
        this.journalData.EntryTo = null
      } else {
        this.getLast7DaysJournals()
        this.journalData = {
          ID: null,
          Uti: null,
          AccountName: null,
          AccountNumber: null,
          AccountCode: null,
          ValueDate: null,
          Currency: null,
          Amount: null,
          Cost: null,
          TransactionType: null,
          ProductType: null,
          CostCenter: null,
          Description: null,
          RelatedTradeUti: null,
          TradeOpsUser: null,
          EntryDate: null,
          EntryFrom: null,
          EntryTo: null,
          ValueFrom: null,
          ValueTo: null,
          selectopt: "All Entries",
        };
        //this.searchForEntries();
  
        this.entryRangeDate = null
        this.valueDateRange = null
        this.journalData.ValueDate = null
        this.journalData.EntryDate = null
        this.journalData.ValueFrom = null
        this.journalData.ValueTo = null
        this.journalData.EntryFrom = null
        this.journalData.EntryTo = null

      }
    },

    clearValuesDate() {
      if (
        this.lastJournalSearchData.find((data) => data.user == this.user.name)
      ) {
        this.runLastSearch();

        this.valueDateRange = null
        this.journalData.ValueDate = null
        this.journalData.ValueFrom = null
        this.journalData.ValueTo = null

      } else {
        this.getLast7DaysJournals()
        this.journalData = {
          ID: null,
          Uti: null,
          AccountName: null,
          AccountNumber: null,
          AccountCode: null,
          ValueDate: null,
          Currency: null,
          Amount: null,
          Cost: null,
          TransactionType: null,
          ProductType: null,
          CostCenter: null,
          Description: null,
          RelatedTradeUti: null,
          TradeOpsUser: null,
          EntryDate: null,
          EntryFrom: null,
          EntryTo: null,
          ValueFrom: null,
          ValueTo: null,
          selectopt: "All Entries",
        };
        //this.searchForEntries();
  
        this.entryRangeDate = null
        this.valueDateRange = null
        this.journalData.ValueDate = null
        this.journalData.EntryDate = null
        this.journalData.ValueFrom = null
        this.journalData.ValueTo = null
        this.journalData.EntryFrom = null
        this.journalData.EntryTo = null

      }
    },

    getJournalEntriesAccountName(item) {
        this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
            this.loading = true;
          if (item.AccountName != null && item.AccountName != "") {

            this.$router.push({
                  path: `/journals/selected-account-names/${item.AccountName}`,
                });
                this.loading = false;
          } else {
            this.warningMessage(this.$t("journals.messages.not_valid_account_name"));
            this.loading = false;
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },
    getJournalEntriesAccountCode(item) {
        this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
            this.loading = true;
          if (item.AccountCode != null && item.AccountCode != "") {

            this.$router.push({
                  path: `/journals/selected-account-codes/${item.AccountCode}`,
                });
                this.loading = false;
          } else {
            this.warningMessage(this.$t("journals.messages.not_valid_account_code"));
            this.loading = false;
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    resetJournalModal() {
      this.journalData = {
        ID: null,
        Uti: null,
        AccountName: null,
        AccountNumber: null,
        AccountCode: null,
        ValueDate: null,
        Currency: null,
        Amount: null,
        Cost: null,
        TransactionType: null,
        ProductType: null,
        CostCenter: null,
        Description: null,
        RelatedTradeUti: null,
        TradeOpsUser: null,
        EntryDate: null,
        EntryFrom: null,
        EntryTo: null,
        ValueFrom: null,
        ValueTo: null,
        selectopt: "All Entries",
      };
      this.JournalModalLoading = false;
      this.journalSearchModal = false;
    },

    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      const sampleColor = "sampleColor";

      if (!item || type !== "row") {
        return;
      }

      // if (item.AmendedBy != null) {
      //   return journalCreateRowColor;
      // }
      // if (item.VerifiedBy != null) {
      //   return verifiedRowColor;
      // }
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("journals.messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    validDateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.please_add_valid_date"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    amendOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.amend_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifiedSecurityMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.entry_already_verify"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityCreateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("journals.messages.security_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successVerifiedMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("journals.messages.security_verify_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successInsertSecurityMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: message,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    notFoundSearchData() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found Search Data",
          text: this.$t("journals.messages.not_found_search_data"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    infoToastMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Amend Note",
            text: message,
            icon: "BookmarkIcon",
            variant: "primary",
          },
        },
        {
          timeout,
        }
      );
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.security_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    UtiExistMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityIsinExistMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.security_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    oldSecurityMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.old_security_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("journals.messages.not_authorized"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("journals.messages.required_fill_inputs") + ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.you_not_change_account"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },


    valueDateRange: {
      handler: function(val, before) {
        if (val) {
          console.log(val)

          if (val.includes("to")) {
            this.journalData.ValueFrom = val.split("to")[0].trim();
            this.journalData.ValueTo = val.split("to")[1].trim();
            this.journalData.ValueDate = null;

          } else {
            this.journalData.ValueDate = val.trim();
          }
        }
      },
    },

    entryRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.journalData.EntryFrom = val.split("to")[0].trim();
            this.journalData.EntryTo = val.split("to")[1].trim();
            this.journalData.EntryDate = null;
          } else {
            this.journalData.EntryDate = val.trim();
          }
        }
      },
    },



  },
};
</script>

<style>
#journalsModal .modal-header .modal-title {
  color: white !important;
}
.padding_08 {
  padding: 0.7rem !important;
}
.verifiedRowColor {
  background-color: greenyellow;
}
</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
